import './App.css';
import Main from './components/main';

function App() {
  return (
    <div className="App font-roboto">
      <Main />
    </div>
  );

}

export default App;


